// Breakpoints
export const breakpoints = {
  xxlarge: "1920",
  xlarge: "1600",
  large: "1024",
  medium: "760",
  small: "480",
};

export const colors = {
  white: "#FAFAFA",
  black: "#0F0D12"
}