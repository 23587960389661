import styled from "styled-components"

const FCR = () => {
  return (
    <Svg 
      viewBox="0 0 119 32" 
      version="1.1" 
      role="presentation"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Icons" fill="#132147">
          <g id="fra-logo">
            <path d="M109.872536,12.4957146 C113.609751,12.4957146 116.724063,15.361651 117.359893,19.1241987 L102.385589,19.1241987 C103.021419,15.361651 106.135731,12.4957146 109.872536,12.4957146 M109.872536,10.9690904 C104.85012,10.9690904 100.764112,15.2642242 100.764112,20.5436127 C100.764112,25.696428 104.775232,29.8951541 109.733224,29.9728102 L109.733224,29.9752561 C109.748612,29.9752561 109.763589,29.976479 109.778772,29.976479 C109.797238,29.976479 109.815498,29.9754599 109.834169,29.9754599 C109.846889,29.9754599 109.85961,29.976479 109.872536,29.976479 C109.915417,29.976479 109.957682,29.9732178 110.000358,29.9724025 C110.078734,29.9705682 110.156905,29.9685299 110.23487,29.9648611 C110.296012,29.9620076 110.357358,29.9591541 110.4185,29.9548739 C114.100113,29.7192561 117.056238,27.4519439 118.218129,23.9172688 L118.262036,23.7837656 L116.683029,23.7837656 L116.657998,23.8500076 C115.57161,26.7389758 113.017008,28.4675873 109.820012,28.4824662 C105.677376,28.4529121 102.311932,24.9506446 102.266794,20.6312561 L117.478688,20.6312561 L118.263267,20.6312561 L118.984448,20.6312561 L118.984448,20.5436127 C118.984448,15.2642242 114.896798,10.9690904 109.872536,10.9690904" id="Fill-1"></path>
            <path d="M100.909066,11.0762395 C98.2348488,10.8000611 95.9951867,11.3915516 94.4358764,12.7877299 C94.1424798,13.0506599 93.8767816,13.3398828 93.6379609,13.6525452 L93.6379609,11.1123159 L92.0991678,11.1123159 L92.0991678,29.5569529 L92.1383557,29.5569529 L92.1383557,29.5591949 L93.6393971,29.5591949 L93.6393971,18.6270166 C93.6393971,16.5718828 94.236654,14.9847236 95.4145488,13.9093605 C96.6665109,12.7663287 98.5596367,12.265335 100.88978,12.4612076 L101.000983,12.4705834 L101.000983,11.0858191 L100.909066,11.0762395 Z" id="Fill-8"></path>
            <path d="M87.8949388,11.1122548 L87.8949388,22.354242 C87.8949388,25.9060382 85.2849405,28.4837707 81.6890888,28.4837707 C78.1125233,28.4837707 75.5166819,25.9060382 75.5166819,22.354242 L75.5166819,11.1122548 L74.0139991,11.1122548 L74.0139991,22.354242 C74.0139991,26.7708535 77.2417716,29.9763567 81.6890888,29.9763567 C86.1554871,29.9763567 89.3974164,26.7708535 89.3974164,22.354242 L89.3974164,11.1122548 L87.8949388,11.1122548 Z" id="Fill-6"></path>
            <polygon id="Fill-10" points="71.4158188 11.3777936 67.8376119 11.3777936 67.8376119 7.61891465 66.2988188 7.61891465 66.2988188 29.556851 67.8376119 29.556851 67.8376119 12.9064561 71.4158188 12.9064561"></polygon>
            <path d="M62.047831,23.850028 C60.9567241,26.7516331 58.3848879,28.4839134 55.1686052,28.4839134 C50.9732397,28.4839134 47.5604017,24.9219261 47.5604017,20.5436331 C47.5604017,16.106028 50.9732397,12.495735 55.1686052,12.495735 C58.2870207,12.495735 60.8463414,14.2161936 62.0143879,17.098028 L62.0402397,17.1618242 L63.6528948,17.1618242 L63.6075517,17.0275057 C62.3422534,13.2904357 59.108531,10.9691108 55.1686052,10.9691108 C50.1447534,10.9691108 46.057719,15.2642446 46.057719,20.5436331 C46.057719,25.7447541 50.1447534,29.9764994 55.1686052,29.9764994 C59.1456672,29.9764994 62.3793897,27.6547669 63.6079621,23.9172892 L63.6516638,23.783786 L62.0728621,23.783786 L62.047831,23.850028 Z" id="Fill-4"></path>
            <path d="M34.5816667,28.402079 C30.4304133,28.402079 27.052865,24.8468178 27.052865,20.4764739 C27.052865,16.1063338 30.4304133,12.5508688 34.5816667,12.5508688 C38.7329202,12.5508688 42.1102633,16.1063338 42.1102633,20.4764739 C42.1102633,24.8468178 38.7329202,28.402079 34.5816667,28.402079 Z M42.1833047,11.1110726 L42.1833047,15.3322191 C40.5644943,12.7406268 37.7626598,11.0222064 34.5816667,11.0222064 C29.5818202,11.0222064 25.5140719,15.2635312 25.5140719,20.4764739 C25.5140719,25.6894166 29.5818202,29.9307414 34.5816667,29.9307414 C37.7626598,29.9307414 40.5644943,28.212321 42.1833047,25.6207287 L42.1833047,29.5569325 L43.7220978,29.5569325 L43.7220978,11.1110726 L42.1833047,11.1110726 Z" id="Fill-12"></path>
            <path d="M12.4365669,25.0794191 C12.4365669,28.0525656 10.0017859,30.4713172 7.00914103,30.4713172 L1.53883414,30.4705019 L1.53883414,18.0439032 C1.53883414,15.0709605 3.97341,12.6522089 6.96626,12.6522089 L12.4371824,12.6522089 L12.4365669,25.0794191 Z M13.9761807,11.1235465 L13.9763859,6.93562293 C13.9763859,3.96268025 16.4111669,1.54392866 19.4038117,1.54392866 L26.0021566,1.54392866 L26.0021566,0.015266242 L19.4038117,0.015266242 C15.5625738,0.015266242 12.4375928,3.11987771 12.4375928,6.93562293 L12.4373876,11.1235465 L6.96626,11.1235465 C3.12502207,11.1235465 4.10344828e-05,14.228158 4.10344828e-05,18.0439032 L4.10344828e-05,31.9987567 L7.00893586,31.9999796 C10.850379,31.9999796 13.97536,28.895572 13.97536,25.0794191 L13.9759755,12.6522089 L20.9007497,12.6522089 C19.3131255,13.921814 18.2944445,15.8664764 18.2944445,18.0439032 L18.2944445,29.524158 L19.8332376,29.524158 L19.8332376,18.0439032 C19.8332376,15.0709605 22.2680186,12.6522089 25.2608686,12.6522089 L27.1349134,12.6522089 L27.1349134,11.1235465 L13.9761807,11.1235465 Z" id="Fill-14"></path>
          </g>
        </g>
      </g>
    </Svg>
  )
}

const Svg = styled.svg``;

export default FCR;