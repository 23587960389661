import styled from "styled-components"
import { respondTo } from "./styles/styleHelpers"

import Intro from "./components/Intro"
import Skills from "./components/Skills"
import Work from "./components/Work"
import Contact from "./components/Contact"

function App() {
  const smoothScroll = (link) => {
    const element = document.getElementById(link);
    let headerOffset = document.getElementById("nav")
    headerOffset = headerOffset.clientHeight;
    const elementPosition = element.getBoundingClientRect().top + window.scrollY;
    const offsetPosition = elementPosition - headerOffset;

    console.log("Element position", elementPosition)

    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth"
    });
  }

  window.onscroll = function() {scrollFunction()};

  function scrollFunction() {
    if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
      document.getElementById("nav").classList.add("-active")
    } else {
      document.getElementById("nav").classList.remove("-active")
    }
  }

  return (
    <Portfolio>
      <Header id="nav">
        <NavList>
          <NavItem onClick={() => {
            smoothScroll("skills")
          }}>
            Skills
          </NavItem>
          <NavItem onClick={() => {
            smoothScroll("work")
          }}>
            Work
          </NavItem>
          <NavItem onClick={() => {
            smoothScroll("contact")
          }}>
            Contact
          </NavItem>
        </NavList>
      </Header>

      <Body>
        <Intro />

        <Skills />

        <Work />

        <Contact />
      </Body>
    </Portfolio>
  );
}

const Portfolio = styled.div`
  
`;

const NavList = styled.ul`
  width: calc(10/12 * 100%);
  max-width: 1500px;
  margin: auto;
  padding: 20px 0px;  
  list-style-type: none;
  display: flex;
  justify-content: flex-end;
  transition: .3s ease-in-out;

  ${respondTo("large")}{
    padding: 30px 0px;
  }
`;


const Header = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 3;
  background-color: #fafafa;
  transition: .3s ease-in-out;

  &.-active{
    box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.3);

    ${NavList}{
      padding: 15px 0px;
    }
  }

  ${respondTo("large")}{
    &.-active{
      ${NavList}{
        padding: 20px 0px;
      }
    }
  }
`;

const NavItem = styled.li`
  color: #0F0D12;
  margin-right: 40px;
  font-size: 18px;
  cursor: pointer;
  position: relative;

  &::after{
    display: block;
    content: "";
    position: absolute;
    bottom: -5px;
    left: 0px;
    height: 4px;
    width: 100%;
    background-color: #3498db;
    transition: .3s ease-in-out;
    transform-origin: left;
    transform: scaleX(0);
  }

  &:hover{
    &::after{
      transform: scaleX(1);
    }
  }

  &:last-of-type{
    margin-right: 0px;
  }

  ${respondTo("large")}{
    font-size: 20px;
  }
`;

const Body = styled.div`
  width: calc(10/12 * 100%);
  max-width: 1500px;
  margin: auto;
  margin-top: 60px;

  ${respondTo("large")}{
    margin-top: 110px;
  }
`;

export default App;
