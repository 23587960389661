import styled from "styled-components"

const LCP = () => {
  return (
    <Svg 
      width="221" 
      height="69" 
      viewBox="0 0 221 69" 
      fill="none" 
      xmlns="http://www.w3.org/2000/svg" 
    >
      <path class="logo-path logo-path-2"
        d="M57.0107 38.1502C49.2365 26.9361 45.1241 13.5808 45.2368 0H24.1113C24.3367 12.172 28.1111 24.1186 35.1529 34.2055C43.3777 46.0394 55.4333 54.6049 69.2916 58.4368V51.4492C64.6722 47.6736 60.5034 43.2218 57.0107 38.1502Z"
        fill="#B5E3D8" />
      <path class="logo-path logo-path-3"
        d="M59.6016 36.4032C62.4183 40.4606 65.6857 44.1798 69.3475 47.3918V0.0563354H48.3911C48.2784 12.9609 52.1655 25.6964 59.6016 36.4032Z"
        fill="#B5E3D8" />
      <path class="logo-path logo-path-1"
        d="M32.6177 36.0088C25.2379 25.3583 21.2381 12.8482 21.0128 0.0563354H0C1.35203 37.5303 31.7727 67.7348 69.3478 68.6928V61.7615C54.4191 57.7605 41.4058 48.6879 32.6177 36.0088Z"
        fill="#B5E3D8" />
      <path d="M88.0508 7.77655H90.3042V18.0889H96.1629V19.9485H88.0508V7.77655Z" fill="#B5E3D8" />
      <path
        d="M111.655 16.9619H106.584L105.514 19.9485H103.261L107.824 7.83289H110.528L115.091 19.9485H112.725L111.655 16.9619ZM111.035 15.1586L109.176 9.97426H109.12L107.26 15.1586H111.035Z"
        fill="#B5E3D8" />
      <path
        d="M126.865 14.7078H124.95V19.8922H122.696V7.77655H124.95V12.9046H126.921L129.851 7.77655H132.329L128.837 13.7498L132.498 19.8922H129.907L126.865 14.7078Z"
        fill="#B5E3D8" />
      <path
        d="M141.286 19.9485V7.83289H149.455V9.63614H143.54V12.9609H148.666V14.7642H143.54V18.1453H149.567V19.9485H141.286Z"
        fill="#B5E3D8" />
      <path d="M158.976 7.77655H161.229V18.0889H167.088V19.9485H158.976V7.77655Z" fill="#B5E3D8" />
      <path
        d="M182.975 16.9619H177.904L176.834 19.9485H174.524L179.088 7.83289H181.792L186.355 19.9485H183.989L182.975 16.9619ZM182.355 15.1586L180.496 9.97426H180.44L178.581 15.1586H182.355Z"
        fill="#B5E3D8" />
      <path
        d="M203.649 7.77655V19.8922H201.508L195.537 11.1013H195.48V19.8922H193.283V7.77655H195.706L201.395 16.2857H201.452V7.77655H203.649Z"
        fill="#B5E3D8" />
      <path
        d="M210.578 7.77655H214.916C219.141 7.77655 221 10.5378 221 13.8062C221 17.3564 218.803 19.8922 214.972 19.8922H210.578V7.77655ZM214.747 18.0889C217.169 18.0889 218.69 16.6801 218.69 13.8625C218.69 10.9322 217.057 9.57981 214.634 9.57981H212.775V18.0889H214.747Z"
        fill="#B5E3D8" />
      <path
        d="M86.9238 34.3746C86.9238 30.8244 88.9519 28.1195 92.6136 28.1195C94.7543 28.1195 96.3317 29.0775 97.2894 30.7117L95.5993 31.7824C94.9797 30.599 94.022 29.9228 92.67 29.9228C90.3039 29.9228 89.2335 31.9514 89.2335 34.3182C89.2335 36.685 90.3039 38.77 92.7263 38.77C94.0783 38.77 95.0923 38.0938 95.712 36.9104L97.3457 37.9247C96.388 39.5589 94.8107 40.5733 92.6136 40.5733C88.9519 40.5733 86.9238 37.9247 86.9238 34.3746Z"
        fill="#B5E3D8" />
      <path
        d="M105.57 40.4042V28.2886H113.682V30.0918H107.767V33.4166H112.894V35.2198H107.767V38.6009H113.795V40.4042H105.57Z"
        fill="#B5E3D8" />
      <path
        d="M132.724 28.2886V40.4042H130.584L124.612 31.6133H124.556V40.4042H122.415V28.2886H124.837L130.527 36.7977H130.584V28.2886H132.724Z"
        fill="#B5E3D8" />
      <path d="M144.329 40.4042V30.0918H140.329V28.2886H150.582V30.1482H146.582V40.4606H144.329V40.4042Z" fill="#B5E3D8" />
      <path
        d="M163.088 35.5016H160.497V40.4042H158.243V28.2886H163.482C165.848 28.2886 167.538 29.5847 167.538 31.7824C167.538 33.4166 166.581 34.5436 165.229 34.9944L167.876 40.4042H165.398L163.088 35.5016ZM160.44 33.6983H163.426C164.44 33.6983 165.229 33.1348 165.229 31.8951C165.229 30.6553 164.384 30.0918 163.426 30.0918H160.44V33.6983Z"
        fill="#B5E3D8" />
      <path
        d="M182.975 37.4176H177.904L176.834 40.4042H174.524L179.088 28.2886H181.792L186.355 40.4042H183.989L182.975 37.4176ZM182.355 35.6707L180.496 30.4863H180.44L178.581 35.6707H182.355Z"
        fill="#B5E3D8" />
      <path d="M194.41 28.2886H196.664V38.601H202.522V40.4606H194.41V28.2886Z" fill="#B5E3D8" />
      <path
        d="M92.5011 48.8007C94.9235 48.8007 96.8952 50.2095 96.8952 52.7453C96.8952 55.2811 94.9798 56.8026 92.4448 56.8026H89.5717V60.9163H87.3184V48.8007H92.5011ZM92.3885 54.943C93.5715 54.943 94.5855 54.2668 94.5855 52.7453C94.5855 51.2238 93.5715 50.5476 92.3885 50.5476H89.5717V54.943H92.3885Z"
        fill="#B5E3D8" />
      <path
        d="M111.655 57.9297H106.584L105.514 60.9163H103.261L107.824 48.8007H110.528L115.091 60.9163H112.725L111.655 57.9297ZM111.035 56.1264L109.176 50.942H109.12L107.26 56.1264H111.035Z"
        fill="#B5E3D8" />
      <path
        d="M127.598 56.0137H125.006V60.9163H122.753V48.8007H127.992C130.358 48.8007 132.048 50.0968 132.048 52.2945C132.048 53.9287 131.09 55.0557 129.738 55.5065L132.386 60.9163H129.907L127.598 56.0137ZM125.006 54.2104H127.992C129.006 54.2104 129.795 53.6469 129.795 52.4072C129.795 51.1674 128.95 50.6039 127.992 50.6039H125.006V54.2104Z"
        fill="#B5E3D8" />
      <path
        d="M144.723 55.6755H142.808V60.8599H140.555V48.7443H142.808V53.8723H144.78L147.709 48.7443H150.188L146.639 54.7739L150.301 60.9163H147.765L144.723 55.6755Z"
        fill="#B5E3D8" />
    </Svg>
  )
}

const Svg = styled.svg``;

export default LCP;