import styled from "styled-components"

const AVC = () => {
  return (
    <Svg 
      width="200" 
      height="100" 
      viewBox="0 0 200 100" 
      fill="none" 
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M200 0.769531H0V74.6245H200V0.769531Z" fill="#9164CC"/>
      <path d="M23.5369 0.769531H0V24.3064H23.5369V0.769531Z" fill="#510C76"/>
      <path d="M23.5369 25.9258H0V49.4627H23.5369V25.9258Z" fill="#510C76"/>
      <path d="M23.5369 51.0898H0V74.6268H23.5369V51.0898Z" fill="#510C76"/>
      <path d="M48.7596 0.769531H25.2227V24.3064H48.7596V0.769531Z" fill="#510C76"/>
      <path d="M48.7596 25.9258H25.2227V49.4627H48.7596V25.9258Z" fill="#510C76"/>
      <path d="M48.7596 51.0898H25.2227V74.6268H48.7596V51.0898Z" fill="#510C76"/>
      <path d="M73.9509 0.769531H50.4141V24.3064H73.9509V0.769531Z" fill="#510C76"/>
      <path d="M73.9509 25.9258H50.4141V49.4627H73.9509V25.9258Z" fill="#510C76"/>
      <path d="M73.9509 51.0898H50.4141V74.6268H73.9509V51.0898Z" fill="#510C76"/>
      <path d="M99.1736 0.769531H75.6367V24.3064H99.1736V0.769531Z" fill="#510C76"/>
      <path d="M99.1736 25.9258H75.6367V49.4627H99.1736V25.9258Z" fill="#510C76"/>
      <path d="M99.1736 51.0898H75.6367V74.6268H99.1736V51.0898Z" fill="#510C76"/>
      <path d="M124.396 0.769531H100.859V24.3064H124.396V0.769531Z" fill="#510C76"/>
      <path d="M124.396 25.9258H100.859V49.4627H124.396V25.9258Z" fill="#510C76"/>
      <path d="M124.396 51.0898H100.859V74.6268H124.396V51.0898Z" fill="#510C76"/>
      <path d="M149.588 0.769531H126.051V24.3064H149.588V0.769531Z" fill="#510C76"/>
      <path d="M149.588 25.9258H126.051V49.4627H149.588V25.9258Z" fill="#510C76"/>
      <path d="M149.588 51.0898H126.051V74.6268H149.588V51.0898Z" fill="#510C76"/>
      <path d="M174.81 0.769531H151.273V24.3064H174.81V0.769531Z" fill="#510C76"/>
      <path d="M174.81 25.9258H151.273V49.4627H174.81V25.9258Z" fill="#510C76"/>
      <path d="M174.81 51.0898H151.273V74.6268H174.81V51.0898Z" fill="#510C76"/>
      <path d="M199.998 0.769531H176.461V24.3064H199.998V0.769531Z" fill="#510C76"/>
      <path d="M199.998 25.9258H176.461V49.4627H199.998V25.9258Z" fill="#510C76"/>
      <path d="M199.998 51.0898H176.461V74.6268H199.998V51.0898Z" fill="#510C76"/>
      <path d="M200 74.625H0V99.784H200V74.625Z" fill="#4C9C2E"/>
      <path d="M19.4341 41.9917C17.7802 41.9917 17.7802 41.9917 17.3349 43.423L15.4583 49.3709C15.4583 49.5299 14.9494 51.0248 14.9494 51.6928C14.9494 53.0923 16.3489 53.1877 17.6212 53.2513C19.0525 53.3149 19.7204 53.4739 19.7204 54.1419C19.7204 54.937 19.0525 54.937 17.7802 54.937C17.2395 54.937 13.9316 54.7144 12.3413 54.7144C11.7369 54.7144 8.46086 54.937 7.72931 54.937C7.41124 54.937 6.67969 54.937 6.67969 54.1101C6.67969 53.3785 7.47485 53.2831 8.33363 53.1559C10.1784 52.9968 10.6555 52.9332 11.7369 50.993C13.5181 47.5579 18.4163 34.5172 22.0741 24.9116C24.1415 19.5045 24.1415 19.4727 24.6822 19.4727C25.2866 19.4727 25.4456 19.8543 26.3044 22.1444C26.8451 23.7029 27.4812 25.2615 27.9265 26.3111L36.6097 47.4625C38.3909 51.7564 38.9316 52.965 41.2217 53.3149C42.1759 53.4103 42.7802 53.5375 42.7802 54.2691C42.7802 55.0006 41.826 55.0006 41.5079 55.0006C40.5537 55.0006 35.9418 54.778 34.9876 54.778C31.5843 54.778 28.6899 55.0006 28.5308 55.0006C27.9901 55.0006 27.2586 55.0006 27.2586 54.2055C27.2586 53.4739 28.1492 53.3149 29.2624 53.2195C30.6937 53.1241 31.2026 52.8378 31.2026 52.0108C31.2026 51.4701 30.9163 50.6113 30.8209 50.3887L28.4354 43.7093C27.8311 42.0554 27.6721 42.0554 26.1453 42.0554H19.4341V41.9917ZM25.2866 39.9879C26.5588 39.9879 26.5588 39.7653 26.5588 39.6062C26.5588 39.32 26.3998 38.8747 26.209 38.5566L22.3285 28.951L18.8934 38.3658C18.8298 38.4612 18.6708 39.0337 18.6708 39.4154C18.6708 39.9561 19.0207 39.9561 20.0067 39.9561H25.2866V39.9879Z" fill="white"/>
      <path d="M57.4729 54.7166C57.3457 55.0347 56.964 55.7663 56.5187 55.7663C56.137 55.7663 55.8508 55.48 55.4691 54.5894L43.2871 25.4864C41.8558 21.9876 41.3469 21.8286 39.6612 21.6378C38.4525 21.5423 37.8164 21.4787 37.8164 20.7472C37.8164 20.1747 38.3253 20.0156 38.707 20.0156C39.693 20.0156 42.651 20.2383 46.5632 20.2383C49.6803 20.2383 51.3978 20.0156 52.5111 20.0156C53.02 20.0156 53.5607 20.1747 53.5607 20.8108C53.5607 21.3515 53.0518 21.5423 52.6065 21.5423C49.9983 21.8604 49.2032 21.924 49.2032 23.1645C49.2032 23.3871 49.4258 23.8324 50.38 26.1225L58.7134 46.097L66.1879 27.1085C66.7604 25.7726 67.1739 24.5004 67.1739 23.3871C67.1739 22.0512 65.838 21.765 64.8838 21.7014C63.2299 21.6378 62.4983 21.5741 62.4983 20.8108C62.4983 20.0156 63.3889 20.0156 63.9933 20.0156C64.3431 20.0156 66.9513 20.2383 68.7324 20.2383C70.2274 20.2383 72.8673 20.0156 73.6943 20.0156C73.9805 20.0156 74.7121 20.0156 74.7121 20.7472C74.7121 21.4151 74.4894 21.4787 72.3266 21.7968C70.9907 21.9558 70.3864 22.6874 68.7006 26.854L57.4729 54.7166Z" fill="white"/>
      <path d="M108.494 26.8546C108.494 27.3953 108.494 27.8088 107.826 27.8088C107.444 27.8088 107.095 27.6816 106.49 26.8228C104.486 23.833 102.419 21.193 97.2982 21.193C88.1697 21.193 83.9394 30.0989 83.9394 37.2236C83.9394 45.0162 88.5196 53.922 96.9165 53.922C102.037 53.922 104.486 50.805 106.808 46.5111C106.904 46.2884 107.381 45.4615 107.922 45.4615C108.749 45.4615 108.749 45.9704 108.749 46.3521L108.303 51.0912C108.24 51.8864 108.144 52.3635 106.967 53.0314C106.077 53.5722 102.355 55.3215 96.7257 55.3215C82.6353 55.3215 76.4648 44.5391 76.4648 37.4462C76.4648 29.7172 83.1442 19.7617 97.6799 19.7617C100.224 19.7617 101.179 19.9208 104.964 20.5251C107.476 20.9704 107.731 21.4793 107.953 22.9742L108.494 26.8546Z" fill="white"/>
      <path d="M133.684 55.2864C123.952 55.2864 114.664 48.0663 114.664 37.411C114.664 27.392 123.061 19.7266 133.875 19.7266C144.276 19.7266 152.673 27.3601 152.673 36.9339C152.673 47.7164 143.163 55.2864 133.684 55.2864ZM133.207 21.1579C125.415 21.1579 121.343 28.3462 121.343 36.2342C121.343 45.2355 126.623 53.8233 134.13 53.8233C141.922 53.8233 145.994 45.2037 145.994 37.1248C145.994 30.509 142.05 21.1579 133.207 21.1579Z" fill="white"/>
      <path d="M186.229 45.7109L186.006 28.5671C185.879 21.8241 185.275 21.8241 183.048 21.7287C181.363 21.6651 180.536 21.6015 180.536 20.8381C180.536 20.043 181.522 20.043 181.935 20.043C182.54 20.043 185.657 20.2656 186.388 20.2656C187.215 20.2656 191.382 20.043 192.336 20.043C192.622 20.043 193.322 20.043 193.322 20.8699C193.322 21.5379 192.749 21.6969 191.159 21.8241C189.092 22.0468 188.265 22.1422 188.328 25.2274L188.424 54.3305C188.424 54.8712 188.424 55.9526 187.597 55.9526C187.151 55.9526 186.77 55.889 185.816 54.6804L162.12 25.1956L162.024 25.3229L162.247 44.4705C162.31 52.5493 162.978 53.09 165.459 53.3127C167.686 53.4717 167.972 53.4717 167.972 54.2033C167.972 54.9984 167.081 54.9984 166.636 54.9984C165.968 54.9984 163.869 54.7758 162.12 54.7758C159.289 54.7758 156.776 54.9984 155.79 54.9984C155.631 54.9984 154.836 54.9984 154.836 54.2669C154.836 53.599 155.059 53.5353 157.38 53.2809C159.83 52.9628 160.052 52.7402 160.052 45.1702V26.5633C160.052 22.2694 159.671 21.7287 157.158 21.7287C155.981 21.6651 155.09 21.6651 155.09 20.8381C155.09 20.1066 155.822 20.043 156.808 20.043C157.412 20.043 159.925 20.2656 161.706 20.2656C163.265 20.2656 164.601 20.043 165.332 20.043C166.318 20.043 166.827 21.2198 167.209 21.6969L186.07 45.8063L186.229 45.7109Z" fill="white"/>
    </Svg>
  )
}

const Svg = styled.svg``;

export default AVC;