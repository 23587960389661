import styled from "styled-components"
import { respondTo } from "../styles/styleHelpers"

const Contact = () => {

  return (
    <Container id="contact">
      <Title>Contact</Title>

      <Body>
        Want to do business or send me spam emails about a Nigerian Prince? <a href="mailto:anthonypn93+portfolio@gmail.com?subject=I'd love to work with you because you're awesome and have great hair">Email me</a> and let's talk.
      </Body>
    </Container>
  )
}

const Container = styled.div`
  margin-bottom: 100px;

  ${respondTo("large")}{
    margin-bottom: 150px;
  }
`;

const Title = styled.h2`
  color: #0F0D12;
  line-height: 1;
  display: inline-flex;
  position: relative;
  font-size: 30px;
  margin-bottom: 30px;
  padding-left: 10px;
  border-left: 6px solid #3498db;

  ${respondTo("large")}{
    font-size: 40px;
    margin-bottom: 40px;
    border-left: 8px solid #3498db;
    padding-left: 15px;
  }

  ${respondTo("xlarge")}{
    font-size: 60px;
    margin-bottom: 50px;
    border-left: 12px solid #3498db;
    padding-left: 20px;
  }
`;

const Body = styled.p`
  margin-bottom: 0px;
  color: #0F0D12;
  font-size: 16px;
  line-height: 1.5;
  
  a{
    color: #0F0D12;
    transition: .3s ease-in-out;

    &:hover{
      color: #3498db;
    }
  }

  max-width: 520px;

  ${respondTo("large")}{
    font-size: 18px;
    max-width: 600px;
  }

  ${respondTo("xlarge")}{
    font-size: 24px;
    max-width: 770px;
  }
`;

export default Contact;