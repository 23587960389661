import styled from "styled-components"

const TLC = () => {
  return (
    <Svg 
      viewBox="0 0 365 146" 
      fill="none" 
      xmlns="http://www.w3.org/2000/svg" 
      role="presentation" 
      focusable="false" 
    >
      <path d="M185.038 58.7294H161.991V42.9811H225.865V58.7294H202.817V123.363H185.038V58.7294Z" fill="#00AEEF"/>
      <path d="M235.413 42.9811H253.521V106.959H286.446V123.363H235.413V42.9811Z" fill="#00AEEF"/>
      <path d="M349.004 63.6503C347.358 61.6818 345.053 60.0413 342.419 58.729C339.785 57.7447 336.822 57.0885 333.529 57.0885C330.237 57.0885 326.944 57.7447 324.31 59.0571C321.347 60.3694 319.042 62.0099 316.738 64.3065C314.433 66.6031 313.116 69.2278 311.799 72.5087C310.482 75.7896 310.153 79.0705 310.153 83.0076C310.153 86.9447 310.811 90.2256 311.799 93.5065C313.116 96.7874 314.762 99.4121 316.738 101.709C318.713 104.005 321.347 105.974 323.981 106.958C326.615 108.271 329.908 108.927 332.871 108.927C336.493 108.927 339.785 108.271 342.419 106.63C345.382 104.99 347.687 103.021 349.333 100.396L364.15 111.551C360.857 116.473 356.248 119.754 350.98 122.05C345.712 124.347 340.444 125.331 334.846 125.331C328.591 125.331 322.664 124.347 317.396 122.378C312.128 120.41 307.519 117.457 303.568 113.848C299.617 110.239 296.654 105.646 294.349 100.396C292.044 95.1469 291.056 89.2413 291.056 83.0076C291.056 76.4458 292.044 70.8683 294.349 65.6189C296.654 60.3694 299.617 55.7762 303.568 52.1672C307.519 48.5582 312.128 45.6054 317.396 43.6368C322.664 41.6683 328.591 40.684 334.846 40.684C337.151 40.684 339.456 41.0121 342.09 41.3402C344.724 41.6683 347.029 42.3245 349.333 43.3087C351.638 44.293 353.943 45.2773 356.248 46.9177C358.552 48.5582 360.528 50.1986 362.174 52.4953L349.004 63.6503Z" fill="#00AEEF"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M84.6167 0L53.6674 53.4786C44.4484 69.227 44.7777 82.6787 50.7041 93.8337C67.8251 80.0539 87.2507 69.227 104.701 69.5551C99.1037 34.4494 84.6167 0 84.6167 0Z" fill="#B7B6B9"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M50.7042 93.8333C44.7778 82.6782 44.4485 69.5546 53.6675 53.4782L42.473 72.8355L0 146C0 146 22.3889 116.143 50.7042 93.8333Z" fill="#5A5A60"/>
      <path d="M62.2279 146H0C0 146 22.3889 116.144 50.7042 93.8339C58.9355 109.254 78.0319 120.409 98.7745 128.283C91.8603 139.11 80.3366 146 62.2279 146Z" fill="#24282F"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M98.4448 128.283C91.8599 139.11 80.3362 146 61.8983 146H84.2871H168.904C169.233 146 132.028 141.407 98.4448 128.283Z" fill="#0082B5"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M98.4451 128.283C132.028 141.406 168.904 146 168.904 146L137.955 92.5209C128.736 76.7726 117.212 70.2108 104.372 69.8827C107.993 91.5366 107.664 113.519 98.4451 128.283Z" fill="#00AEEF"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M104.701 69.5551C117.213 69.8831 129.066 76.4449 138.285 92.1933L127.09 72.836L84.6173 0C84.6173 0 99.1042 34.4494 104.701 69.5551Z" fill="#0082B5"/>
    </Svg>
  )
}

const Svg = styled.svg``;

export default TLC;