import styled from "styled-components";

const RCG = () => {
  return (
    <Svg
      width="1544"
      height="1533"
      viewBox="0 0 1544 1533"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M528.153 457.73H787.267V567.271C809.687 543.416 845.94 416.7 1079.49 424.429V690.266C1079.49 690.266 803.677 581.011 800.529 872.993C797.189 1172.04 800.529 1125.57 800.529 1125.57H529.871L528.153 457.73Z"
        fill="#BDBBBB"
      />
      <path
        d="M228.49 766.497C228.49 499.419 421.299 277.57 675.261 231.865V0.855469C294.604 48.5649 0 372.989 0 766.497C0 1160 294.604 1484.43 675.261 1532.14V1301.22C421.299 1255.42 228.49 1033.48 228.49 766.497Z"
        fill="#043673"
      />
      <path
        d="M1544 766.5C1544 370.702 1246.15 44.7515 862.157 0V230.627C1119.46 273.566 1315.61 497.132 1315.61 766.5C1315.61 1035.87 1119.46 1259.43 862.157 1302.28V1533C1246.15 1488.34 1544 1162.2 1544 766.5Z"
        fill="#043673"
      />
    </Svg>
  );
};

const Svg = styled.svg``;

export default RCG;
