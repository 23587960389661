import {useState} from "react"
import styled from "styled-components"
import { respondTo } from "../styles/styleHelpers"

const Intro = () => {
  const options = [
    {
      name: "Hulk Anthony",
      filter: 90
    },
    {
      name: "Yondu Anthony",
      filter: 180
    },
    {
      name: "Inevitable Anthony",
      filter: 270
    }
  ]
  
  const [status, setStatus] = useState(0);

  return (
    <Container>
      <Content>
      <Title>Hello, I'm Anthony.</Title>
      <Body>
        I am a developer specialized in both front-end and back-end web development. I'm currently working at Big Vision as a full-stack developer, dealing with custom Wordpress sites as well as React based web applications.
      </Body>
      </Content>

      <ProfileImage option={options[status]} onMouseLeave={() => {
        let tempStatus = status;

        if(tempStatus === 2){
          tempStatus = 0
        }else{
          tempStatus++;
        }

        setTimeout(() => {
          setStatus(tempStatus)
        }, 300)
      }}>
        <Image src="profile.jpg" alt="Anthony Headshot" />
      </ProfileImage>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column-reverse;
  margin-bottom: 70px;

  ${respondTo("large")}{
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 100px;
  }

  ${respondTo("xlarge")}{
    margin-bottom: 150px;
  }
`;

const Content = styled.div`
  max-width: 430px;

  ${respondTo("large")}{
    max-width: 480px;
  }

  ${respondTo("xlarge")}{
    max-width: 750px;
  }
`;

const Title = styled.h2`
  color: #FAFAFA;
  line-height: 1;
  padding-bottom: 5px;
  display: inline-flex;
  font-size: 30px;
  margin-bottom: 10px;
  padding: 5px;
  position: relative;

  &::before{
    display: block;
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background-color: #0F0D12;
    z-index: -1;
    transform-origin: left;
    animation-delay: 1s;
    transform: scaleX(0);
    animation: .7s title linear forwards;
    animation-delay: 1.3s;
  }

  @keyframes title {
    from{
      transform: scaleX(0);
    }

    to{
      transform: scaleX(1);
    }
  }

  ${respondTo("small")}{
    font-size: 34px;
  }

  ${respondTo("large")}{
    font-size: 50px;
    margin-bottom: 20px;
    padding: 10px;

    &::before{
      animation-delay: .5s;
    }
  }

  ${respondTo("xlarge")}{
    font-size: 80px;
    margin-bottom: 30px;
    padding: 15px;
  }
`;

const Body = styled.p`
  margin-bottom: 0px;
  color: #0F0D12;
  font-size: 16px;
  line-height: 1.5;

  ${respondTo("large")}{
    font-size: 18px;
  }

  ${respondTo("xlarge")}{
    font-size: 24px;
  }
`;

const ProfileImage = styled.div`
  position: relative;
  height: 300px;
  width: 100%;
  overflow: hidden;
  box-shadow: 0px 0px 30px -10px rgba(0, 0, 0, .5);
  transition: .3s ease-in-out;
  margin-bottom: 30px;
  max-width: 400px;

  &::before{
    display: block;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    background-color: #0F0D12;
    height: 100%;
    width: 100%;
    z-index: 2;
    transform: scaleX(1);
    transform-origin: right;
    animation: .7s profile linear forwards;
    animation-delay: .5s;
  }

  @keyframes profile {
    from{
      transform: scaleX(1);
    }

    to{
      transform: scaleX(0);
    }
  }


  &::after{
    opacity: 0;
    content: '${props => props.option ? props.option.name : ""}';
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    color: #FAFAFA;
    background-color: #0F0D12;
    line-height: 1;
    padding: 5px;
    z-index: 2;
    font-weight: bold;
    transition: .3s ease-in-out;
    text-align: center;
  }
  
  &:hover{
    filter: ${props => props.option ? `hue-rotate(${props.option.filter}deg)` : ""};
    transform: translateY(-10px);
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, .5);

    &::after{
      opacity: 1;
    }
  }

  ${respondTo("small")}{
    height: 400px;
  }

  ${respondTo("large")}{
    height: 400px;
    width: 400px;
    margin-bottom: 0px;
    margin-left: 70px;
    max-width: 100%;
    
    &::before{
      animation-delay: 1.2s;
    }
  }

  ${respondTo("xlarge")}{
    height: 500px;
    width: 500px;
    margin-bottom: 0px;
  }
`;

const Image = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export default Intro;