import styled from "styled-components";
import { respondTo } from "../styles/styleHelpers";

import AVC from "../svgs/avc";
import CCC from "../svgs/ccc";
import FCR from "../svgs/fcr";
import LCP from "../svgs/lcp";
import PDG from "../svgs/pdg";
import TLC from "../svgs/tlc";
import YMC from "../svgs/ymc";
import RCG from "../svgs/rcg";

const Work = () => {
  return (
    <Container id="work">
      <Title>Work</Title>

      <Cards>
        <Card href="https://ymcacf.org/" target="_blank">
          <CardLogo>
            <YMC />
          </CardLogo>

          <CardTitle>YMCA Central Florida</CardTitle>
        </Card>

        <Card href="https://avconinc.com/" target="_blank">
          <CardLogo>
            <AVC />
          </CardLogo>

          <CardTitle>AVCON</CardTitle>
        </Card>

        <Card href="https://tlc-engineers.com/" target="_blank">
          <CardLogo>
            <TLC />
          </CardLogo>

          <CardTitle>TLC Engineering</CardTitle>
        </Card>

        <Card href="https://blog.fractureme.com/" target="_blank">
          <CardLogo>
            <FCR />
          </CardLogo>

          <CardTitle>Fracture Blog</CardTitle>
        </Card>

        <Card href="https://citruscoffee.com/" target="_blank">
          <CardLogo>
            <CCC />
          </CardLogo>

          <CardTitle>Citrus Coffee</CardTitle>
        </Card>

        <Card href="https://lakelandcentralpark.com/" target="_blank">
          <CardLogo>
            <LCP />
          </CardLogo>

          <CardTitle>Lakeland Central Park</CardTitle>
        </Card>

        <Card href="https://resourceconsulting.com/" target="_blank">
          <CardLogo>
            <RCG />
          </CardLogo>

          <CardTitle>Resource Consulting Group</CardTitle>
        </Card>

        <Card href="https://proteandg.com/" target="_blank">
          <CardLogo>
            <PDG />
          </CardLogo>

          <CardTitle>Protean Design Group</CardTitle>
        </Card>
      </Cards>
    </Container>
  );
};

const Container = styled.div`
  margin-bottom: 70px;

  ${respondTo("large")} {
    margin-bottom: 100px;
  }

  ${respondTo("xlarge")} {
    margin-bottom: 150px;
  }
`;

const Title = styled.h2`
  color: #0f0d12;
  line-height: 1;
  display: inline-flex;
  position: relative;
  font-size: 30px;
  margin-bottom: 30px;
  padding-left: 10px;
  border-left: 6px solid #3498db;

  ${respondTo("large")} {
    font-size: 40px;
    margin-bottom: 40px;
    border-left: 8px solid #3498db;
    padding-left: 15px;
  }

  ${respondTo("xlarge")} {
    font-size: 60px;
    margin-bottom: 50px;
    border-left: 12px solid #3498db;
    padding-left: 20px;
  }
`;

const Cards = styled.div`
  display: grid;

  ${respondTo("medium")} {
    grid-template-columns: repeat(2, 1fr);
  }

  ${respondTo("large")} {
    grid-template-columns: repeat(4, 1fr);
  }
`;

const CardTitle = styled.h3`
  margin-bottom: 0px;
  color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, 50%);
  opacity: 0;
  text-align: center;
  transition: 0.3s ease-in-out;
  padding: 5px;
  background-color: #0f0d12;
  line-height: 1;
  font-size: 16px;
  white-space: nowrap;
`;

const Card = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #0f0d12;
  position: relative;
  filter: grayscale(1);
  transition: 0.3s ease-in-out;
  height: 250px;

  &:nth-child(even) {
    background-color: #eeeeee;
  }

  &:nth-child(1) {
    order: 1;
  }

  &:nth-child(2) {
    order: 2;
  }

  &:nth-child(3) {
    order: 3;
  }

  &:nth-child(4) {
    order: 4;
  }

  &:nth-child(5) {
    order: 6;
    background-color: #eeeeee;
  }

  &:nth-child(6) {
    order: 5;
    background-color: #0f0d12;
  }

  &:nth-child(7) {
    order: 8;
    background-color: #eeeeee;
  }

  &:nth-child(8) {
    order: 7;
    background-color: #0f0d12;
  }

  &:hover {
    filter: grayscale(0);

    ${CardTitle} {
      opacity: 1;
      transform: translate(-50%, -50%);
    }
  }

  ${respondTo("small")} {
    height: 300px;
  }

  ${respondTo("medium")} {
    height: 200px;

    &:nth-child(3) {
      order: 4;
    }

    &:nth-child(4) {
      order: 3;
    }

    &:nth-child(6) {
      background-color: #0f0d12;
    }

    &:nth-child(7) {
      order: 7;
    }

    &:nth-child(8) {
      background-color: #0f0d12;
    }
  }

  ${respondTo("large")} {
    height: 200px;

    &:nth-child(3) {
      order: 3;
    }

    &:nth-child(6) {
      order: 6;
    }
  }

  ${respondTo("xlarge")} {
    height: 300px;
  }
`;

const CardLogo = styled.div`
  width: 50%;
  margin: auto;

  svg {
    width: 100%;
    height: auto;
  }
`;

export default Work;
