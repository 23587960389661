import styled from "styled-components"

const YMC = () => {
  return (
    <Svg 
      width="477" 
      height="364" 
      viewBox="0 0 477 364" 
      fill="none" 
      xmlns="http://www.w3.org/2000/svg" 
    >
      <path class="logo-arrow" d="M141.5 0.0996094C133.7 0.0996094 127.3 6.39961 127.3 14.2996C127.3 16.6996 127.9 19.0996 129 20.9996L214.6 169.3C218.5 176 220.8 183.7 220.8 192C220.8 200 218.7 207.6 215 214.1L140.5 343.1C139.4 345 138.8 347.4 138.8 349.8C138.8 357.6 145.2 364 153 364H225C240.4 364 254.2 356.3 261.4 343.8L262.9 341.2L334.5 217.2L335.8 214.9C339.4 208.6 341.1 201.3 341.1 193.7C341.1 185.7 339 178.1 335.3 171.7L334.5 170.4L249.5 22.8996L248 20.2996C240.8 7.79961 227 0.0996094 211.6 0.0996094H141.5Z" fill="#178BCD"/>
      <path class="logo-letters" d="M137.5 231.4C136.2 231.4 134.9 231.9 133.7 232.2C129.7 233.1 125.2 233.9 120.4 233.9C117.8 233.9 115.4 233.3 113.9 232.7C110.5 231.2 110.2 226.9 110.3 221.5H136.9C140.7 221.5 141.5 220.9 141.5 214.9C141.5 207.9 140.8 201.1 137.5 196.2C134.3 191.7 128.9 189.4 119.8 189.4C110.2 189.4 104.2 191.9 100.9 196.6C97.5 201.4 96.5 207.8 96.5 217.4C96.5 228.2 98 235.3 102.6 239.5C106.2 242.9 112.1 244.6 120.3 244.6C125 244.6 131.4 244.2 136.7 243.2C140.1 242.7 140.6 240.9 140.6 238.4C140.6 237.2 140.6 235.9 140.4 234.8C140.2 233.4 139.2 231.4 137.5 231.4ZM110.3 210.9C110.3 203 111.9 200.2 119.8 200.2C127.2 200.2 128.2 203 128.2 209.8V211.9H110.3V210.9ZM71.4001 189.4C65.5001 189.4 60.3001 191.4 56.7001 193.1V172.9C56.7001 170.8 55.8 168.2 52.5 168.2H47.6C44.4 168.2 42.8 169.2 42.8 172.9V238.8C42.8 241.4 43.7001 243.1 46.7001 243.1H52.5C55.9 243.1 56.7001 241.3 56.7001 238.2V213.2C56.7001 205.4 58.3 202.7 66 202.7C73.3 202.7 74.3001 205.5 74.3001 212.1V214.1C74.3001 215.2 74.3001 216.3 74.3001 217.7V238.1C74.3001 241.4 75.4 243 78.2 243H84C87.4001 243 88.2 241.2 88.2 238.1V204.7C88.1 197.8 84.8 189.4 71.4001 189.4Z" fill="#178BCD"/>
      <path class="logo-letters" d="M31.4 202.4H19.6V224.3C19.6 229.4 19.8 231.4 20.3 232.3C20.6 232.9 21.2 233.2 21.9 233.2C25 233.2 28.3 231.6 30.8 231.6C33.2 231.6 33.8 233.4 33.8 234.6V238.5C33.8 242.5 32.7 243.6 29.2 243.9C25.7 244.2 23.3 244.4 19.7 244.4C8.1 244.4 5.6 240.2 5.6 227.2V202.4H2.6C0.5 202.4 0 201 0 199.2V194.5C0 191.8 1.1 190.9 2.9 190.9H5.6V182.1C5.6 178.4 7.1 177.3 10.3 177.3H15.2C18.6 177.3 19.6 180 19.6 182.1V190.9H30.3C32.7 190.9 34 191.2 34 194.4V198.2C34 202 33.3 202.4 31.4 202.4Z" fill="#178BCD"/>
      <path class="logo-copyright" d="M456.1 10C456.1 4.5 460.6 0 466.1 0C471.6 0 476.1 4.5 476.1 10C476.1 15.5 471.6 20 466.1 20C460.6 20.1 456.1 15.6 456.1 10ZM458.2 10C458.2 14.4 461.8 17.9 466.2 18C470.6 18 474.1 14.4 474.2 10C474.2 5.6 470.6 2 466.2 2C461.8 2.1 458.2 5.6 458.2 10Z" fill="#8F2F8D"/>
      <path class="logo-copyright" d="M469.9 15.1H468.9C468.5 15.1 468.3 15.1 468 14.7L465.3 11.3H464.6V14.5C464.6 14.9 464.4 15 464.1 15H463.4C462.9 15 462.8 14.9 462.8 14.5V5.5C462.8 5 463.1 5 463.4 5H466.3C467.9 5 468.3 5.1 468.9 5.7C469.4 6.2 469.7 7.2 469.7 8.5C469.7 10.2 469 11.1 467.5 11.3L470.1 14.4C470.4 14.7 470.4 15.1 469.9 15.1ZM467.5 6.6C467.1 6.4 465.2 6.5 464.7 6.5V9.8H466.6C467.3 9.8 467.6 9.7 467.8 9.5C467.9 9.3 468 9.1 468 8.4C468 7.2 467.8 6.8 467.5 6.6Z" fill="#8F2F8D"/>
      <path class="logo-name" d="M287.9 329.6L296.3 343.7L304.2 348.3C305.3 348.9 305.5 349.8 304.9 350.8L304 352.4C303.3 353.6 302.7 354 301.4 353.2L293.8 348.8L276.5 348.6C276 348.6 275.6 348.5 275.2 348.4C274.3 347.9 274.5 346.9 274.9 346.3L276 344.3C276.6 343.2 277.2 343.1 278.1 343.1L290 343.3L283.9 333C283.5 332.3 283.3 331.7 283.9 330.6L284.8 329C285.4 328 286.1 327.9 286.8 328.3C287.2 328.5 287.7 329.2 287.9 329.6Z" fill="#8F2F8D"/>
      <path class="logo-name" d="M324.9 316L324 317.5C323.4 318.6 322.6 318.7 321.5 318.2L306 310.5C306.6 311.3 307.6 312.3 308.3 313.1C311.1 316.4 314.1 319.7 315.9 322C316.6 322.9 316.9 323.6 316.2 324.8L315.6 325.8C314.9 327 314.2 327.1 313 327C310.2 326.6 305.7 325.7 301.5 325C300.4 324.8 299.1 324.5 298.1 324.3L312.5 333.9C313.5 334.6 313.8 335.3 313.2 336.4L312.3 337.9C311.6 339 310.9 339.6 309.6 338.7L289.3 324.5C288 323.6 288.4 322.7 288.9 321.9L290.1 319.9C290.7 318.9 291.3 318.5 292.3 318.7C296.5 319.3 303.8 320.7 309.4 321.7C305.7 317.3 300.9 311.7 298.3 308.4C297.7 307.6 297.6 306.9 298.2 305.9L299.4 303.9C299.9 303 300.5 302.3 301.9 302.9L324.4 313.4C325.7 313.9 325.6 314.9 324.9 316Z" fill="#8F2F8D"/>
      <path class="logo-name" d="M336.4 296.8C335.6 298.5 335.1 299.4 334.2 301.1C333.4 302.5 332.4 304.1 331.3 305.2C327.1 309.6 322 308.2 315.7 304.5C304.9 298.3 304.9 293.7 309 286.5C310 284.8 310.6 283.9 311.6 282.4C312.6 281 313.2 280.8 314 281.2L315.2 281.9C316.5 282.7 316.8 283.1 316.1 284.3C315.3 285.7 314.8 286.2 313.6 288.2C312.9 289.4 311.9 291.3 311.8 292.5C311.5 295.2 314.4 297 318.6 299.4C322.8 301.8 326.1 303.5 328.5 300.6C329 300 329.7 299 330.3 297.9C331.4 296 331.6 295.2 332.4 293.8C333.1 292.6 333.7 292.7 335 293.4L336.2 294.1C337.1 294.6 337.2 295.2 336.4 296.8Z" fill="#8F2F8D"/>
      <path class="logo-name" d="M349.9 272.8L348.7 274.8C348 275.9 347.3 275.9 346.4 275.8L340.9 274.8C340 276.4 339.4 277.6 338.4 279.2C337.5 280.7 336.7 282.1 335.9 283.4L339.4 287.4C340.2 288.3 340.6 288.9 339.9 290.2L338.9 291.9C338.2 293.2 337.7 293.1 337.2 292.8C337 292.7 336.6 292.3 336.2 291.8L319.5 272.1C318.6 271 318.3 270.3 319.1 269L320 267.5C320.8 266.1 321.5 266 322.9 266.3L348.9 270.7C349.1 270.7 349.3 270.8 349.5 270.9C350.5 271.3 350.2 272.2 349.9 272.8ZM326 271.9L333 279.9C333.5 279 334 278 334.6 277C335.3 275.9 335.7 275.1 336.3 274L326 271.9Z" fill="#8F2F8D"/>
      <path class="logo-triangle" d="M290.9 0.0996094C280.8 0.0996094 272.5 8.29961 272.5 18.4996C272.5 21.6996 273.2 24.5996 274.7 27.1996L343.5 146.4C346.7 151.9 352.6 155.6 359.4 155.6C366.2 155.6 372.1 151.9 375.3 146.4L444.1 27.1996C445.6 24.5996 446.3 21.5996 446.3 18.4996C446.3 8.39961 438.1 0.0996094 427.9 0.0996094H290.9Z" fill="#8F2F8D"/>
    </Svg>
  )
}

const Svg = styled.svg``;

export default YMC;