import styled from "styled-components";
import { respondTo } from "../styles/styleHelpers";

import Html from "../skills/html";
import Css from "../skills/css";
import Sass from "../skills/sass";
import Javscript from "../skills/javascript";
import React from "../skills/react";
import Typescript from "../skills/typescript";
import Node from "../skills/node";
import Git from "../skills/git";

const Skills = () => {
  return (
    <Container id="skills">
      <Title>Skills</Title>

      <AllSkills>
        <Skill>
          <SkillIcon>
            <Html />
          </SkillIcon>
          <SkillTitle>HTML</SkillTitle>
        </Skill>
        <Skill>
          <SkillIcon>
            <Css />
          </SkillIcon>
          <SkillTitle>CSS</SkillTitle>
        </Skill>
        <Skill>
          <SkillIcon>
            <Javscript />
          </SkillIcon>
          <SkillTitle>Javscript</SkillTitle>
        </Skill>
        <Skill>
          <SkillIcon>
            <Typescript />
          </SkillIcon>
          <SkillTitle>Typescript</SkillTitle>
        </Skill>
        <Skill>
          <SkillIcon>
            <React />
          </SkillIcon>
          <SkillTitle>React</SkillTitle>
        </Skill>
        <Skill>
          <SkillIcon>
            <Node />
          </SkillIcon>
          <SkillTitle>Node</SkillTitle>
        </Skill>
        <Skill>
          <SkillIcon>
            <Sass />
          </SkillIcon>
          <SkillTitle>Sass</SkillTitle>
        </Skill>
        <Skill>
          <SkillIcon>
            <Git />
          </SkillIcon>
          <SkillTitle>Git</SkillTitle>
        </Skill>
      </AllSkills>
    </Container>
  );
};

const Container = styled.div`
  margin-bottom: 70px;

  ${respondTo("large")} {
    margin-bottom: 100px;
  }

  ${respondTo("xlarge")} {
    margin-bottom: 150px;
  }
`;

const Title = styled.h2`
  color: #0f0d12;
  line-height: 1;
  display: inline-flex;
  position: relative;
  font-size: 30px;
  margin-bottom: 30px;
  padding-left: 10px;
  border-left: 6px solid #3498db;

  ${respondTo("large")} {
    font-size: 40px;
    margin-bottom: 40px;
    border-left: 8px solid #3498db;
    padding-left: 15px;
  }

  ${respondTo("xlarge")} {
    font-size: 60px;
    margin-bottom: 50px;
    border-left: 12px solid #3498db;
    padding-left: 20px;
  }
`;

const AllSkills = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
`;

const SkillIcon = styled.div`
  display: flex;
  width: 100px;
  margin-bottom: 10px;
  transition: 0.3s ease-in-out;

  svg {
    width: 100%;
    height: auto;
  }

  path {
    transition: 0.3s ease-in-out;
  }
`;

const SkillTitle = styled.p`
  color: #0f0d12;
  font-weight: 600;
  transition: 0.3s ease-in-out;

  ${respondTo("large")} {
    font-size: 18px;
  }
`;

const Skill = styled.div`
  display: flex;
  width: 50%;
  flex-direction: column;
  align-items: center;
  margin-bottom: 50px;

  &:hover,
  &:focus {
    ${SkillIcon} {
      transform: translateY(-7px);
    }

    path:first-of-type {
      fill: #3498db;
    }

    ${SkillTitle} {
      color: #3498db;
    }
  }

  ${respondTo("large")} {
    width: 25%;
  }
`;

export default Skills;
