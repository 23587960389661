import styled from "styled-components"

const PDG = () => {
  return (
    <Svg 
        width="135px" 
        height="42px" 
        viewBox="0 0 135 42" 
        version="1.1" 
        xmlns="http://www.w3.org/2000/svg" 
    >
        <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="Navbar-Mobile">
                <g id="Navbar">
                    <g id="Logo">
                        <path d="M9.98356401,16.0975313 C9.98356401,14.1560047 9.24606795,12.8300785 7.52477194,12.8300785 C6,12.8300785 5.01643599,14.0612794 5.01643599,15.8133556 L5.01643599,25.1418607 C5.01643599,26.6572373 6.09837999,27.746464 7.52477194,27.746464 C9.44259201,27.746464 9.98356401,26.4205378 9.98356401,24.9051612 L9.98356401,16.0975313 Z M0,8.28417571 L5.01643599,8.28417571 L5.01643599,10.4624019 C6.29490406,9.0889995 7.27870399,8 9.78703995,8 C11.80324,8 13.278704,8.71032571 14.163888,10.2729514 C14.754168,11.3147019 15,12.7826023 15,15.4344547 L15,24.5737364 C15,28.0306397 14.852312,29.4038149 14.213196,30.3983164 C13.377084,31.6767663 12.147452,32.5760882 9.73773199,32.5760882 C7.77060396,32.5760882 6.19675999,31.6767663 5.11481598,30.1141406 L5.01643599,30.1141406 L5.01643599,42 L0,42 L0,8.28417571 Z" id="Fill-1" fill="#FFFFFF"></path>
                        <path d="M20,8.28075819 L25.1645785,8.28075819 L25.1645785,10.8069086 C27.2913268,9.07590308 29.012691,8 32,8 L32,13.0527497 C31.4938164,12.8655776 30.9873899,12.7719915 30.4304423,12.7719915 C28.2534156,12.7719915 25.1645785,14.0350667 25.1645785,17.9181028 L25.1645785,32 L20,32 L20,8.28075819 Z" id="Fill-3" fill="#FFFFFF"></path>
                        <path d="M64.5971306,0 L69.7918804,0 L69.7918804,7.45546516 L73,7.45546516 L73,11.328229 L69.7918804,11.328229 L69.7918804,24.9319041 C69.7918804,27.2073503 70.708486,27.3524976 73,27.3524976 L73,32 L70.8614165,32 C66.1760277,32 64.5971306,28.8532052 64.5971306,26.2389271 L64.5971306,11.328229 L62,11.328229 L62,7.45546516 L64.5971306,7.45546516 L64.5971306,0 Z" id="Fill-5" fill="#FFFFFF"></path>
                        <path d="M84.0331377,18.0809317 L88.9673357,18.0809317 L88.9673357,15.3987614 C88.9673357,13.6416305 88.0297766,12.716813 86.5002367,12.716813 C84.9706968,12.716813 84.0331377,13.6416305 84.0331377,15.3987614 L84.0331377,18.0809317 Z M84.0331377,21.7804233 L84.0331377,24.9712543 C84.0331377,26.4508735 84.9706968,27.283187 86.5002367,27.283187 C88.4248248,27.283187 88.8688695,25.9422128 88.9673357,24.9248914 L94,24.9248914 C94,26.8670302 93.2600833,28.6703022 91.977419,29.9192162 C90.645285,31.2138275 88.8193997,32 86.5002367,32 C83.441157,32 81.1219939,30.6590258 79.9375592,28.624383 C79.2468756,27.421832 79,25.7572049 79,23.861429 L79,16.1387929 C79,14.2427951 79.2468756,12.5779462 79.9375592,11.375617 C81.1219939,9.34097421 83.441157,8 86.5002367,8 C91.1383261,8 94,11.1906091 94,15.3064793 L94,21.7804233 L84.0331377,21.7804233 Z" id="Fill-7" fill="#FFFFFF"></path>
                        <path d="M109.095941,21.6417784 C108.567343,21.5490526 108.182657,21.5029115 107.749769,21.5029115 C105.634456,21.5029115 103.903828,22.3352251 103.903828,24.8326093 C103.903828,26.6358813 104.865314,27.8382106 106.451799,27.8382106 C108.086716,27.8382106 109.095941,26.7286071 109.095941,24.8326093 L109.095941,21.6417784 Z M109.095941,29.3180516 L109.000231,29.3180516 C107.701799,31.0288197 106.644142,32 104.288284,32 C102.028598,32 99,30.9365376 99,25.7572049 C99,20.3930862 100.586716,17.8034199 106.740544,17.8034199 C107.413515,17.8034199 108.086716,17.8959238 109.095941,17.9422867 L109.095941,15.4912654 C109.095941,13.9191422 108.471172,12.716813 106.644142,12.716813 C105.394373,12.716813 104.144373,13.5491265 103.855627,15.0751086 L99.0964022,15.0751086 C99.2405443,12.9943248 100.009917,11.236972 101.307426,9.98850171 C102.605627,8.73980959 104.432657,8 106.644142,8 C110.730858,8 114,10.4971624 114,15.3987614 L114,31.7224882 L109.095941,31.7224882 L109.095941,29.3180516 Z" id="Fill-9" fill="#FFFFFF"></path>
                        <path d="M120,8.28075819 L125.016436,8.28075819 L125.016436,10.5730557 L125.114816,10.5730557 C126.147688,9.07590308 127.524772,8 129.835876,8 C132.344212,8 135,9.5438334 135,13.8012138 L135,32 L129.983328,32 L129.983328,15.5791245 C129.983328,13.848119 129.098144,12.7719915 127.524772,12.7719915 C125.656024,12.7719915 125.016436,13.9414807 125.016436,16.0468304 L125.016436,32 L120,32 L120,8.28075819 Z" id="Fill-11" fill="#FFFFFF"></path>
                        <path d="M21.7191744,35.3635254 L20.3629558,35.3635254 L20.3629558,40.6369325 L21.7191744,40.6369325 C22.9502727,40.6369325 23.6368303,39.9433041 23.6368303,38.7687142 L23.6368303,37.2310568 C23.6368303,36.056467 22.9584002,35.3635254 21.7191744,35.3635254 M24,37.2310568 L24,38.7687142 C24,40.1465853 23.1784836,41 21.7125441,41 L20.1030906,41 C20.023313,41 20,40.9748188 20,40.8898894 L20,35.1098817 C20,35.0256391 20.023313,35 20.1030906,35 L21.7125441,35 C23.1784836,35 24,35.8543304 24,37.2310568" id="Fill-13" fill="#FFFFFF"></path>
                        <path d="M33.8755448,35.1096612 L33.8755448,35.2623626 C33.8755448,35.3379121 33.8491525,35.3633242 33.7588378,35.3633242 L30.4108959,35.3633242 L30.4108959,37.7799908 L33.3760291,37.7799908 C33.4549637,37.7799908 33.4830508,37.8058608 33.4830508,37.8818681 L33.4830508,38.0334249 C33.4830508,38.1181319 33.4549637,38.1444597 33.3760291,38.1444597 L30.4108959,38.1444597 L30.4108959,40.6369048 L33.8849879,40.6369048 C33.9641646,40.6369048 34,40.661859 34,40.7374084 L34,40.8901099 C34,40.9752747 33.9641646,41 33.8849879,41 L30.116707,41 C30.0263923,41 30,40.9752747 30,40.8901099 L30,35.1096612 C30,35.02587 30.0263923,35 30.116707,35 L33.7588378,35 C33.8491525,35 33.8755448,35.02587 33.8755448,35.1096612" id="Fill-15" fill="#FFFFFF"></path>
                        <path d="M43.4858489,35 C44.7389973,35 45.4494356,35.3982086 45.9348849,36.2593789 C45.9825177,36.3325013 45.9528739,36.3736877 45.8776242,36.3974745 L45.6921603,36.4710374 C45.6174173,36.4952647 45.5898004,36.4952647 45.5421676,36.422803 C45.1220867,35.6988474 44.5335174,35.3572425 43.4858489,35.3572425 C42.2821069,35.3572425 41.5810431,35.8122752 41.5810431,36.5932751 C41.5810431,37.5524558 42.6175635,37.70707 43.6730864,37.8039791 C44.814247,37.9185082 46,38.0889803 46,39.3413112 C46,40.3980618 45.1593315,41 43.532975,41 C42.2248462,41 41.4690553,40.5436458 41.0211042,39.5774172 C40.9833526,39.4954849 40.991207,39.4631084 41.0862192,39.4305117 L41.2635755,39.3736877 C41.3464262,39.3485794 41.3742964,39.3573893 41.4216759,39.4391014 C41.8237678,40.2599662 42.4873334,40.6416563 43.532975,40.6416563 C44.8793621,40.6416563 45.5700378,40.2196608 45.5700378,39.3573893 C45.5700378,38.3984289 44.5991392,38.2761912 43.5803544,38.1700316 C42.4120837,38.0482343 41.1411997,37.8779825 41.1411997,36.6179429 C41.1411997,35.6259452 42.0099918,35 43.4858489,35" id="Fill-17" fill="#FFFFFF"></path>
                        <path d="M51.2841981,35 L51.7387972,35 C51.9357311,35 52,35.0256391 52,35.1096528 L52,40.8898894 C52,40.9748188 51.9357311,41 51.7387972,41 L51.2841981,41 C51.0630896,41 51,40.9748188 51,40.8898894 L51,35.1096528 C51,35.0256391 51.0630896,35 51.2841981,35" id="Fill-19" fill="#FFFFFF"></path>
                        <path d="M63,38.0893139 L63,39.0403803 C63,40.2271209 62.1262601,41 60.5090726,41 C58.8732359,41 58,40.2271209 58,38.9831137 L58,36.9992658 C58,35.7638486 58.8540827,35 60.4989919,35 C61.9397681,35 62.6287802,35.487427 62.9430444,36.414045 C62.9725302,36.4955398 62.9430444,36.5195477 62.8603831,36.5521457 L62.6935484,36.6010425 C62.609123,36.6252707 62.562248,36.6089718 62.5345262,36.5356264 C62.2555444,35.7314709 61.7081653,35.3493264 60.4989919,35.3493264 C59.1151714,35.3493264 58.4276714,35.9667046 58.4276714,36.9908961 L58.4276714,38.9831137 C58.4276714,40.0240446 59.1522177,40.6495723 60.5090726,40.6495723 C61.8475302,40.6495723 62.5814012,40.0240446 62.5814012,39.0403803 L62.5814012,38.3333578 L60.593246,38.3333578 C60.4989919,38.3333578 60.4712702,38.3005396 60.4712702,38.2274146 L60.4712702,38.0809442 C60.4712702,38.0078191 60.4989919,37.9745604 60.593246,37.9745604 L62.8785282,37.9745604 C62.9725302,37.9745604 63,38.0078191 63,38.0893139" id="Fill-21" fill="#FFFFFF"></path>
                        <path d="M73,35.1096612 L73,40.8901099 C73,40.9752747 72.9717996,41 72.8851288,41 L72.6856566,41 C72.6189072,41 72.5888958,40.9919872 72.5526752,40.9324634 L69.1650109,36.6565934 C68.8506675,36.2591575 68.5927248,35.8031136 68.3911829,35.5579212 L68.3725551,35.5579212 C68.3725551,35.8791209 68.4292145,36.4201007 68.4292145,36.9516941 L68.4292145,40.8901099 C68.4292145,40.9752747 68.4015316,41 68.3143434,41 L68.1252199,41 C68.0282004,41 68,40.9752747 68,40.8901099 L68,35.1096612 C68,35.02587 68.0282004,35 68.1252199,35 L68.3143434,35 C68.3911829,35 68.4292145,35.0341117 68.4493946,35.058837 L71.740298,39.2257326 C72.0926731,39.6735348 72.3896823,40.146978 72.6189072,40.4345238 L72.6370175,40.4345238 C72.6370175,40.0622711 72.5707855,39.496337 72.5707855,38.9125458 L72.5707855,35.1014194 C72.5707855,35.02587 72.5987271,35 72.6954879,35 L72.8851288,35 C72.9717996,35 73,35.02587 73,35.1096612" id="Fill-23" fill="#FFFFFF"></path>
                        <path d="M92,38.0893139 L92,39.0403803 C92,40.2271209 91.1265561,41 89.5089461,41 C87.8739479,41 87,40.2271209 87,38.9831137 L87,36.9992658 C87,35.7638486 87.8547956,35 89.498866,35 C90.9400736,35 91.6285469,35.487427 91.9430472,36.414045 C91.9730356,36.4955398 91.9430472,36.5195477 91.8608941,36.5521457 L91.6938158,36.6010425 C91.6088907,36.6252707 91.56227,36.6089718 91.5350537,36.5356264 C91.2560859,35.7314709 90.7084824,35.3493264 89.498866,35.3493264 C88.1153672,35.3493264 87.4284058,35.9667046 87.4284058,36.9908961 L87.4284058,38.9831137 C87.4284058,40.0240446 88.1524117,40.6495723 89.5089461,40.6495723 C90.8478403,40.6495723 91.5819263,40.0240446 91.5819263,39.0403803 L91.5819263,38.3333578 L89.5933673,38.3333578 C89.498866,38.3333578 89.4719016,38.3005396 89.4719016,38.2274146 L89.4719016,38.0809442 C89.4719016,38.0078191 89.498866,37.9745604 89.5933673,37.9745604 L91.8785343,37.9745604 C91.9730356,37.9745604 92,38.0078191 92,38.0893139" id="Fill-25" fill="#FFFFFF"></path>
                        <path d="M100.817179,35.3635254 L98.4398039,35.3635254 L98.4398039,38.0006868 L100.817179,38.0006868 C101.77082,38.0006868 102.34342,37.5352919 102.34342,36.6903472 C102.34342,35.8451736 101.791012,35.3635254 100.817179,35.3635254 M102.803415,36.6903472 C102.803415,37.6621137 102.153934,38.2451736 101.121598,38.3207173 C101.484003,38.4562381 101.693939,38.6682182 101.905946,39.0143457 L102.974522,40.8898894 C103.023187,40.9489508 103.002737,41 102.907995,41 L102.726533,41 C102.573547,41 102.524881,40.982831 102.486829,40.9068295 L101.494357,39.1658909 C101.179842,38.6082411 100.817179,38.3632965 100.014711,38.3632965 L98.4398039,38.3632965 L98.4398039,40.8898894 C98.4398039,40.9748188 98.411847,41 98.3248699,41 L98.1258062,41 C98.0287335,41 98,40.9748188 98,40.8898894 L98,35.1098817 C98,35.0256391 98.0287335,35 98.1258062,35 L100.865585,35 C102.067216,35 102.803415,35.6167112 102.803415,36.6903472" id="Fill-27" fill="#FFFFFF"></path>
                        <path d="M111.508695,35.3493007 C110.152024,35.3493007 109.427693,35.9668539 109.427693,37.0070477 L109.427693,38.9916309 C109.427693,40.0322652 110.152024,40.6491576 111.508695,40.6491576 C112.847472,40.6491576 113.572307,40.0322652 113.572307,38.9916309 L113.572307,37.0070477 C113.572307,35.9668539 112.847472,35.3493007 111.508695,35.3493007 M111.508695,35 C113.126468,35 114,35.7640128 114,37.0070477 L114,38.9916309 C114,40.2353265 113.126468,41 111.508695,41 C109.873784,41 109,40.2353265 109,38.9916309 L109,37.0070477 C109,35.7640128 109.873784,35 111.508695,35" id="Fill-29" fill="#FFFFFF"></path>
                        <path d="M121.505017,41.6444212 C122.890032,41.6444212 123.562857,40.9577012 123.562857,39.8893954 L123.562857,36.1076153 C123.562857,36.0255586 123.591417,36 123.677096,36 L123.885761,36 C123.971955,36 124,36.0255586 124,36.1076153 L124,39.8893954 C124,41.1727076 123.174343,42 121.505017,42 C119.8254,42 119,41.1727076 119,39.8893954 L119,36.1076153 C119,36.0255586 119.028045,36 119.121957,36 L119.32239,36 C119.406525,36 119.436371,36.0255586 119.436371,36.1076153 L119.436371,39.8893954 C119.436371,40.9577012 120.110225,41.6444212 121.505017,41.6444212" id="Fill-31" fill="#FFFFFF"></path>
                        <path d="M134.617508,36.8421916 C134.617508,35.9127399 134.076757,35.3633103 133.210004,35.3633103 L131.366106,35.3633103 L131.366106,38.3041322 L133.210004,38.3041322 C134.076757,38.3041322 134.617508,37.763173 134.617508,36.8421916 M135,36.8421916 C135,37.9834026 134.323415,38.6683582 133.241483,38.6683582 L131.366106,38.6683582 L131.366106,40.890343 C131.366106,40.9755046 131.342605,41 131.270375,41 L131.103924,41 C131.023286,41 131,40.9755046 131,40.890343 L131,35.1101148 C131,35.0260979 131.023286,35 131.103924,35 L133.241483,35 C134.314791,35 135,35.6844977 135,36.8421916" id="Fill-33" fill="#FFFFFF"></path>
                        <path d="M30.0098324,24.8406826 C29.6691025,18.2895985 38.2330956,9.99308609 48.0645097,9.01482765 C55.1439107,8.72744476 58.8848658,12.6462618 56.0458428,18.2678002 C54.209674,22.5276063 49.6403561,26.3745776 44.7380896,25.8901195 C41.6439318,25.4926325 39.0039234,23.1855619 40.5910881,20.2901571 C40.6729105,20.1538059 41.4901907,19.0058314 41.8054543,19.1564183 C41.9488202,19.2445016 41.4585936,20.5532948 41.4302977,20.7626039 C41.1459238,24.257242 45.6454451,25.3716292 49.3088223,23.716286 C54.4143478,21.2205925 56.6351052,15.7990211 54.8211015,12.6424804 C52.2240085,8.83043104 44.4480565,9.34625218 38.2738889,13.0319599 C32.2258738,16.9968204 29.6542471,22.1917331 30.7471767,26.6450556 C30.7615604,26.6897646 30.9369951,27.1866789 30.7514211,26.9235412 C30.3253318,26.3096273 30.127732,25.5446817 30.0098324,24.8406826" id="Fill-35" fill="#7AC143"></path>
                        <path d="M35.1244882,14.2492964 C42.0018744,8.13317752 52.7151686,5.90280855 57.1228219,10.5560596 C59.7609776,14.0748749 57.8239968,20.2044466 52.623088,24.429066 C48.684294,27.5368774 42.9541567,27.817761 40.5891891,24.05958 C39.3038192,21.5139997 41.047243,17.71778 45.2002653,16.4942779 C47.4931188,15.9274079 49.4251668,16.6659161 49.2842272,18.5469781 C49.2365426,19.0368428 48.6523476,20.5551452 49.9238584,19.5568602 C51.8387587,17.9956483 51.3442954,15.8065653 50.149127,14.8678898 C46.9963065,12.7059442 41.15929,15.2890993 38.5603625,18.8744824 C35.2304279,24.1059687 39.3470407,28.7225727 45.2928157,28.9990494 C51.943289,29.0876518 57.6060101,22.9678218 59.3865479,16.8781444 C61.374502,11.4441714 58.4323863,7.53035629 51.9883897,7.01242639 C44.8810369,6.73154278 36.1336162,11.2453958 32.2021041,17.2072723 C31.9364328,17.6154929 31.8955603,17.7444535 32.3063995,17.2541249 C33.1832791,16.2189609 34.0791856,15.224387 35.1244882,14.2492964" id="Fill-37" fill="#9DCE70"></path>
                        <path d="M61.5369055,15.1771093 C60.3014543,22.7713398 52.6133797,30.2716118 44.8782561,30.5242658 C39.5521788,30.6984712 34.3617552,26.3608697 35.6165514,20.8436168 C36.6406409,16.7166353 43.692242,12.2522572 48.8095846,12.9441336 C51.9938628,13.6031918 53.4788881,16.7944096 50.7555457,19.9876503 C49.164959,21.6905922 46.8504881,22.4388885 45.470069,21.2940782 C45.1201877,20.989275 44.4256792,19.7813012 44.0915605,21.1106568 C43.6337294,23.1453756 45.8273539,24.0809147 47.6448262,23.8889516 C52.1865961,23.2058418 55.3231089,18.2303114 54.7702248,14.642355 C53.5505362,9.68638063 44.8758678,10.2278784 39.5887193,13.6344364 C31.9572466,18.552198 31.4034072,25.3405882 35.6320751,29.1778268 C39.5920629,32.7714028 46.1335299,33.0191116 52.2938293,29.4250861 C58.8560743,25.2974302 63.0417534,17.4784185 61.7738218,11.7478484 C61.6847394,11.3574036 61.617629,11.2602981 61.6434223,11.7844877 C61.6911877,12.89648 61.6880829,13.9972332 61.5369055,15.1771093" id="Fill-39" fill="#7AC143"></path>
                    </g>
                </g>
            </g>
        </g>
    </Svg>
  )
}

const Svg = styled.svg``;

export default PDG;