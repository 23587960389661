import { breakpoints } from "./theme";

export const respondTo = (breakpoint, direction = "min") => {
  let size;
  for (const viewportName in breakpoints) {
    const viewportValue = breakpoints[viewportName];
    if (breakpoint === viewportName) {
      if (direction === "min") {
        size = `${viewportValue}px`;
      } else {
        size = `${viewportValue - 1}px`;
      }
      break;
    }
  }
  if (direction === "min") {
    return `@media (min-width: ${size})`;
  } else {
    return `@media (max-width: ${size})`;
  }
};